import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://mombasa.app.1life.com',
  myoneServer: 'https://mombasa.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-mombasa',
  },
  stripePublishableKey:
    'pk_test_51NtDn0IJNQT5GUstPb4J3QYUyXaEk0cFogx2Z378TBzSHyXbYYXnPD26zAuRDNZBAfGh11oxhINI4sw6DDDnOr4O00RwloqdAO',
  launchDarklyClientId: '604ff765824e970bccf17f37',
  googleTagManagerId: 'GTM-5PQD',
};
