import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { MembershipService } from '@app/core/membership.service';
import { RegistrationEdge } from '@app/registration/graph/graph-navigation.service';
import { CompleteRegistrationNode } from '@app/registration/graph/nodes/complete-registration.node';

@Injectable({
  providedIn: 'root',
})
export class MembershipUpdateToCompleteRegistrationEdge implements RegistrationEdge {
  nextNode = CompleteRegistrationNode;

  constructor(private membershipService: MembershipService) {}

  shouldNavigate$(): Observable<boolean> {
    return this.membershipService.getMembership(true).pipe(
      filter(membership => membership.isActive),
      take(1),
      map(() => true),
    );
  }
}
